import { Stack, IStackTokens, IStackStyles } from "@fluentui/react";
import { Dclid } from "./Dclid";

export type DclidConfigurationProps = {
  config: DclidManagement;
  onChange: (config: DclidManagement) => void;
  disabled: boolean;
};

export function DclidConfiguration({
  config,
  onChange,
  disabled
}: DclidConfigurationProps) {
  const configuration = config.configuration;
  if (configuration == null || configuration.value == null) return <></>;
  const renderedConfigs = configuration.value.map(dclidConfig => {
    return (
      <Dclid
        key={dclidConfig.destination.code}
        destination={dclidConfig.destination}
        replacement={dclidConfig.replacement}
        replacementCarriers={dclidConfig.replacementCarriers}
        allCarriers={config.managedCarriers}
        onChange={(destination, replacment, replacementCarriers) => {
          let newDclids: DclidConfig[] = [];
          if (destination && replacment && replacementCarriers) {
            // Update old key,val with new
            newDclids = configuration.value.map(existingDclid =>
              existingDclid.destination === destination
                ? {
                    destination: destination,
                    replacement: replacment,
                    replacementCarriers: replacementCarriers
                  }
                : existingDclid
            );
          } else {
            // Remove key
            //newConfig = config.filter(
            //  ([oldDest, _]) => oldDest !== destinationCountry
            //);
          }
          var newConfig: DclidManagement = {
            ...config,
            configuration: { ...config.configuration, value: newDclids }
          };
          onChange(newConfig);
        }}
        disabled={disabled}
      />
    );
  });

  const wrapStackTokens: IStackTokens = { childrenGap: 30 };
  const stackStyes: IStackStyles = { root: { margin: 30 } };
  return (
    <Stack
      horizontal
      horizontalAlign="space-evenly"
      wrap
      tokens={wrapStackTokens}
      styles={stackStyes}
    >
      {renderedConfigs}
    </Stack>
  );
}
