import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { User, Role } from "./models/user";
import { useMsal, useAccount } from "@azure/msal-react";
import { useQuery } from "@tanstack/react-query";
import { PortalContextResponse, getPortalContext } from "./services/settings";
import { ErrorDetails } from "./components/Helpers/ErrorDetails";
import {
  AccessTokenProviderParams,
  getAccessToken
} from "./components/Auth/AuthProviderUtils";
import { setAccessTokenProvider } from "./services/http-client";

const PortalContextLocal = createContext<PortalContext | undefined>(undefined);

export function PortalContextProvider(props: {
  children: React.ReactNode;
}): JSX.Element {
  const contextQuery = useContextQuery();
  const [context, setContext] = useState<PortalContext | undefined>(undefined);

  useEffect(() => {
    if (contextQuery.status === "success") {
      setContext(contextQuery.data as PortalContext);
    }
  }, [contextQuery.status, contextQuery.data]);

  const setCurrentUser = useCallback((user: User) => {
    setContext(prevContext => {
      if (!prevContext) {
        throw new Error("PortalContext has not been initialized");
      }

      // Perform a deep comparison to check if the user has actually changed
      if (JSON.stringify(prevContext.currentUser) !== JSON.stringify(user)) {
        return { ...prevContext, currentUser: user };
      }

      return prevContext;
    });
  }, []);

  const contextValue = useMemo(() => {
    if (context) {
      return { ...context, setCurrentUser };
    }
    return undefined;
  }, [context, setCurrentUser]);

  if (contextValue) {
    return (
      <PortalContextLocal.Provider value={contextValue}>
        {props.children}
      </PortalContextLocal.Provider>
    );
  }

  if (contextQuery.status === "error") {
    return <ErrorDetails error={contextQuery.error} />;
  }

  return <>Loading...</>;
}

export function usePortalContext(): PortalContext {
  const context = useContext(PortalContextLocal);

  if (!context) {
    throw new Error("PortalContext has not been initialized");
  }

  return context;
}

export function useCurrentUser(): User | null {
  const context = usePortalContext();
  const { accounts, instance } = useMsal();
  const account = useAccount(accounts[0] || {});

  const user = useMemo(() => {
    if (!account) {
      return null;
    }

    const roles: Role[] = (account.idTokenClaims?.roles || []) as Role[];
    return new User({ email: account.username, roles: roles });
  }, [account]);

  useEffect(() => {
    setAccessTokenProvider(async (params: AccessTokenProviderParams) => {
      return await getAccessToken(instance, {
        scopes: context.msal.scopes,
        ...params
      });
    });
  }, [context.msal.scopes, instance]);

  useEffect(() => {
    if (user) {
      context.setCurrentUser(user);
    }
  }, [user, context]);

  return context.currentUser;
}

function useContextQuery() {
  // NB! Structural sharing does not work, i.e., equality checks will return false,
  // because of the User class, and this will cause components to re-render on refetch
  return useQuery<PortalContextResponse, PortalApiError>({
    queryKey: ["portal-context"],
    queryFn: getPortalContext,
    staleTime: Infinity
  });
}
