import {
  ActionButton,
  IButtonProps,
  Separator,
  Text,
  getTheme
} from "@fluentui/react";
import { Card, CardItem, ICardTokens } from "@fluentui/react-cards";
import { DclidCarrierList } from "./DclidCarrierList";
import { CountrySelect } from "../../components/Helpers/CountrySelect";
import { CarrierSelect } from "./CarrierSelect";
import { Country } from "../../models/country";

type DclidProps = {
  destination?: Country | undefined;
  replacement?: Country | undefined;
  replacementCarriers?: CarrierConfig[] | undefined;
  allCarriers: CarrierConfig[];
  onChange: (
    destionationCountry: Country | undefined,
    replacementCountry: Country | undefined,
    replacementCarriers: CarrierConfig[] | undefined
  ) => void;
  showDelete?: boolean;
  disabled?: boolean;
};

const theme = getTheme();
const countryStyles = {
  root: { paddingLeft: 12, paddingRight: 12, paddingTop: 8 }
};
const replacementCarriersStyles = {
  root: { paddingLeft: 12, paddingRight: 12, paddingBottom: 8 }
};
const deleteButtonProps: IButtonProps = {
  styles: {
    label: { color: theme.palette.redDark },
    labelHovered: { color: theme.semanticColors.buttonTextHovered }
  },
  iconProps: {
    iconName: "Delete",
    styles: { root: { color: theme.palette.redDark } }
  }
};

export function Dclid(props: DclidProps) {
  const stackTokens: ICardTokens = {
    childrenGap: 12,
    padding: 12,
    maxWidth: 1000
  };
  // Already selected replacements cannot be added
  // const disabledNewCarriers = [...(props.replacementCarriers ?? [])];
  //if (props.destinationCountry) {
  //  disabledNewCarriers.push(props.destinationCountry);
  //}
  // Don't allow selecting already selected destination countries and
  // replacement countries as a destination
  //const disabledDestinationCountries = [
  //  ...(props.disabledDestinationCountries ?? []),
  //  ...(props.replacementCountries ?? [])
  //];
  const shouldRenderReplacements = (props.replacementCarriers?.length ?? 0) > 0;
  return (
    <Card
      tokens={stackTokens}
      data-testid="DclidConfiguration"
      className="CustomCard"
    >
      <CardItem grow disableShrink align="center">
        <Text variant="smallPlus">Destination Country</Text>
      </CardItem>
      <CardItem grow disableShrink styles={countryStyles}>
        <CountrySelect
          // XXX: DCLID
          disabledCountries={[]}
          selectedCountry={props.destination}
          onChange={destinationCountry => {
            props.onChange(
              destinationCountry,
              props.replacement,
              props.replacementCarriers
            );
          }}
          disabled={props.disabled}
        />
      </CardItem>
      <CardItem grow disableShrink align="center">
        <Text variant="smallPlus">Replacement Country</Text>
      </CardItem>
      <CardItem grow disableShrink styles={countryStyles}>
        <CountrySelect
          // XXX: DCLID
          disabledCountries={[]}
          selectedCountry={props.replacement}
          onChange={replacementCountry => {
            props.onChange(
              props.destination,
              replacementCountry,
              props.replacementCarriers
            );
          }}
          disabled={props.disabled}
        />
      </CardItem>
      {shouldRenderReplacements && (
        <CardItem grow disableShrink>
          <Separator>
            <Text variant="smallPlus">Replacement Carriers</Text>
          </Separator>
        </CardItem>
      )}
      {shouldRenderReplacements && (
        <CardItem grow disableShrink>
          <DclidCarrierList
            disabledCarriers={[]}
            selectedCarriers={props.replacementCarriers || []}
            allCarriers={props.allCarriers}
            onChange={selectedCarriers => {
              props.onChange(
                props.destination,
                props.replacement,
                selectedCarriers
              );
            }}
            disabled={props.disabled}
          />
        </CardItem>
      )}
      <CardItem grow disableShrink>
        <Separator>
          <Text variant="smallPlus">Add Replacement Carrier</Text>
        </Separator>
      </CardItem>
      <CardItem grow disableShrink styles={replacementCarriersStyles}>
        <CarrierSelect
          onChange={selectedCarrier => {
            const selectedCarrierConf: CarrierConfig = {
              ...selectedCarrier,
              percentage: 0
            };
            const newReplacements = [
              ...(props.replacementCarriers ?? []),
              selectedCarrierConf
            ];
            props.onChange(
              props.destination,
              props.replacement,
              newReplacements
            );
          }}
          disabled={props.disabled}
          allCarriers={props.allCarriers}
        />
      </CardItem>
      {(props.showDelete ?? true) && (
        <CardItem grow align="center">
          <ActionButton
            disabled={props.disabled}
            iconProps={deleteButtonProps.iconProps}
            styles={deleteButtonProps.styles}
            onClick={() => props.onChange(undefined, undefined, undefined)}
          >
            Delete
          </ActionButton>
        </CardItem>
      )}
    </Card>
  );
}
