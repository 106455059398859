import { useState, useEffect } from "react";
import { DefaultButton, Modal, PrimaryButton, Stack } from "@fluentui/react";
import { Dclid } from "./Dclid";

type AddNewDclidProps = {
  isShown: boolean;
  allCarriers: CarrierConfig[];
  onCreate: (
    destination: Country,
    replacement: Country,
    replacementCarriers: CarrierConfig[]
  ) => void;
  onDismiss: () => void;
};

type DclidState = Readonly<{
  destination?: Country;
  replacement?: Country;
  replacementCarriers?: CarrierConfig[];
}>;

export function AddNewDclid({
  isShown,
  onCreate,
  onDismiss,
  allCarriers
}: AddNewDclidProps) {
  const [{ destination, replacement, replacementCarriers }, setValue] =
    useState<DclidState>({});
  useEffect(() => {
    if (!isShown) {
      setValue({});
    }
  }, [isShown]);

  const isCreateButtonDisabled =
    !destination || !replacement || !replacementCarriers;
  return (
    <Modal
      isOpen={isShown}
      styles={{ scrollableContent: { overflowY: "unset" } }}
    >
      <Dclid
        destination={destination}
        replacement={replacement}
        replacementCarriers={replacementCarriers}
        showDelete={false}
        allCarriers={allCarriers}
        onChange={(destination, replacement, replacementCarriers) =>
          setValue({ destination, replacement, replacementCarriers })
        }
      />
      <Stack
        horizontal
        horizontalAlign="end"
        tokens={{ padding: 12, childrenGap: 12 }}
      >
        <PrimaryButton
          disabled={isCreateButtonDisabled}
          onClick={() =>
            onCreate(destination!, replacement!, replacementCarriers!)
          }
        >
          Add
        </PrimaryButton>
        <DefaultButton autoFocus={true} onClick={onDismiss}>
          Cancel
        </DefaultButton>
      </Stack>
    </Modal>
  );
}
