import { ApiGet, ApiPost } from "./serviceClient";

const url = "/ui/api/Dclid";

export async function fetchConfiguration(): Promise<
  ApiResponse<DclidManagement>
> {
  const response = await ApiGet<DclidManagement>(url);
  if (response.isSuccess && response.data) {
    return response;
  } else {
    return {
      ...response,
      data: null,
      isSuccess: false,
      isFailed: true
    };
  }
}

export async function saveConfiguration(
  dclid: DclidManagement,
  changeReason: string
): Promise<ApiResponse<never>> {
  dclid.configuration.id = dclid.configuration.id ?? "";
  return ApiPost(url, dclid.configuration, { reason: changeReason });
}
