import { useHistory, useLocation } from "react-router-dom";

import { INavLink, INavLinkGroup, Nav } from "@m365-admin/nav";
// import { useM365Theme } from "@m365-admin/customizations";
import { useM365CustomizerTheme } from "@m365-admin/customizations";
import { User } from "../../../models/user";
import { Icon } from "@fluentui/react";

import "./LeftNav.scss";

type LeftNavProps = {
  currentUser: User;
};

export const LeftNav = ({ currentUser }: LeftNavProps) => {
  const history = useHistory();
  const location = useLocation();
  let pathArray = location.pathname.split("/");

  const compStyles = {
    root: { height: "100%" }
  };

  const renderExternalLinkIcon = (item: any) => {
    return (
      <>
        {item.name}
        <Icon iconName="NavigateExternalInline" className="externalIcon" />
      </>
    );
  };

  // Setting up Nav theme over-rides
  // const theme = useM365Theme();
  const theme = useM365CustomizerTheme();
  theme.semanticColors.navBackground = "#33344a"; // styles.brand70
  theme.semanticColors.navItemBackgroundHovered = "#464775"; // styles.brand60
  theme.semanticColors.navItemBackgroundPressed = "#8b8cc7"; // styles.brand40

  // Nested Nav theme over-rides
  const nestedLinkStyles = {
    nestedNavLinks: {
      "background-color": "#33344a"
    },
    nestedNavHeaderItem: {
      "background-color": "#464775"
    }
  };

  const _handleLinkClick = (ev: any, data: any) => {
    history.push(data.url);
  };

  const _handleExternalLinkClick = (ev: any, data: any) => {
    window.open(data.url);
  };

  const getMenuGroups = (): INavLinkGroup[] => {
    let menuList: INavLink[] = [];
    menuList = addCarrierMenuItem(menuList);
    menuList = addOperatorConnectMenuItem(menuList);
    menuList = addRoutingMenuItem(menuList);
    menuList = addDynamicRoutingMenuItem(menuList);
    menuList = addToolsMenuItem(menuList);
    menuList = addSettings(menuList);
    menuList = addInspector(menuList);
    menuList = addAbout(menuList);

    menuList.forEach(x => {
      x.external && (x.name = renderExternalLinkIcon(x));
      x.external && (x.onClick = _handleExternalLinkClick);
      x.navLinkGroupStyles = nestedLinkStyles;
      x.links?.forEach(y => {
        y.external && (y.name = renderExternalLinkIcon(y));
        y.external
          ? (y.onClick = _handleExternalLinkClick)
          : (y.onClick = _handleLinkClick);
        y.isSelected = pathArray.includes(y.key);
      });
      x.isSelected = pathArray.includes(x.key);
      x.defaultIsExpanded = pathArray.includes(x.key);
    });

    return [{ links: menuList, key: "group" }];
  };

  // NB: For each child route, the 'key' must match the end of the url
  // for the navigation component to be able to highlight the active route
  // EG:  url: "/ui/carrier/carriers" =>  key: "carriers"

  const addCarrierMenuItem = (currentMenuList: INavLink[]): INavLink[] => {
    if (
      currentUser.hasCarrierManagementRights() ||
      currentUser.hasDynamicRoutingRights()
    ) {
      currentMenuList = currentMenuList.concat([
        {
          name: "Carrier",
          icon: "Home",
          key: "carrier",
          links: [
            {
              name: "Carriers",
              url: "/ui/carrier/carriers",
              key: "carriers"
            },
            {
              name: "Carrier Gateways",
              url: "/ui/carrier/gateways",
              key: "gateways"
            },
            {
              name: "Carrier Test Results",
              url: "/ui/carrier/testresults",
              key: "testresults"
            },
            {
              name: "Emer. Calling Routing",
              url: "/ui/carrier/ec-routing",
              key: "ec-routing"
            }
          ]
        }
      ]);
    }
    return currentMenuList;
  };

  const addOperatorConnectMenuItem = (
    currentMenuList: INavLink[]
  ): INavLink[] => {
    if (
      currentUser.hasCarrierManagementRights() ||
      currentUser.hasDynamicRoutingRights()
    ) {
      currentMenuList = currentMenuList.concat([
        {
          name: "Operator Connect",
          icon: "Communications",
          key: "operatorconnect",
          links: [
            {
              name: "Calling Profiles",
              url: "/ui/operator-connect/calling-profiles",
              key: "calling-profiles"
            }
          ]
        }
      ]);
    }
    return currentMenuList;
  };

  const addRoutingMenuItem = (currentMenuList: INavLink[]): INavLink[] => {
    const links: INavLink[] = [];

    const routingAccess =
      currentUser.hasCarrierManagementRights() ||
      currentUser.hasDynamicRoutingRights();

    const clidAccess = routingAccess || currentUser.hasClidReadRights();

    if (routingAccess) {
      links.push(
        {
          name: "Routing Documents",
          url: "/ui/routing/documents",
          key: "documents"
        },
        {
          name: "Temporary Routing Numbers",
          url: "/ui/routing/trns",
          key: "trns"
        }
      );
    }

    if (clidAccess) {
      links.push(
        {
          name: "CLID replacement",
          url: "/ui/routing/clidreplacement",
          key: "clidreplacement"
        },
        {
          name: "CLID restriction",
          url: "/ui/routing/clidrestriction",
          key: "clidrestriction"
        },
        {
          name: "DCLID management",
          url: "/ui/routing/dclid",
          key: "dclid"
        },
        {
          name: "Global Emergency Numbers",
          url: "/ui/routing/global-emergency-numbers",
          key: "global-emergency-numbers"
        }
      );
    }

    if (links.length) {
      currentMenuList.push({
        name: "Routing",
        icon: "News",
        key: "routing",
        links: links
      });
    }

    return currentMenuList;
  };

  const addDynamicRoutingMenuItem = (
    currentMenuList: INavLink[]
  ): INavLink[] => {
    if (currentUser.hasDynamicRoutingRights()) {
      currentMenuList = currentMenuList.concat([
        {
          name: "Dynamic Routing",
          icon: "Relationship",
          key: "dynamic-routing",
          links: [
            {
              name: "Route Management",
              url: "/ui/routing/route-management",
              key: "route-management"
            },
            {
              name: "Route Configuration",
              url: "/ui/routing/icq",
              key: "icq"
            },
            {
              name: "Prefix Configuration",
              url: "/ui/routing/prefixes",
              key: "prefixes"
            },
            {
              name: "CLI Configuration",
              url: "/ui/routing/cli",
              key: "cli"
            },
            {
              name: "Batman",
              url: "/ui/routing/batman",
              key: "batman"
            },
            {
              name: "Consumer Routing",
              url: "/ui/routing/consumer",
              key: "consumer"
            }
          ]
        }
      ]);
    }
    return currentMenuList;
  };

  const addToolsMenuItem = (currentMenuList: INavLink[]): INavLink[] => {
    currentMenuList = currentMenuList.concat([
      {
        name: "Tools",
        icon: "DeveloperTools",
        key: "tools",
        links: getToolsMenuSubItems()
      }
    ]);
    return currentMenuList;
  };

  const getToolsMenuSubItems = (): INavLink[] => {
    let result: INavLink[] = [];
    if (
      currentUser.hasCarrierManagementRights() ||
      currentUser.hasSupportToolsRights()
    ) {
      result = result.concat([
        {
          name: "SIP Diagnostics",
          url: "/ui/tools/sip-diagnostics",
          key: "sip-diagnostics"
        }
      ]);
    }

    result = result.concat([
      {
        name: "Call Finder",
        url: "/ui/tools/callfinder",
        key: "callfinder"
      }
    ]);

    if (currentUser.hasAdminRights()) {
      result = result.concat([
        {
          name: "Inspector Request",
          url: "/ui/tools/inspector-request/",
          key: "inspector-request"
        },
        {
          name: "IVT Reprovisioning",
          url: "/ui/tools/ivt-reprovisioning/",
          key: "ivt-reprovisioning"
        }
      ]);
    }
    return result;
  };

  const addInspector = (currentMenuList: INavLink[]): INavLink[] => {
    if (
      currentUser.hasCarrierManagementRights() ||
      currentUser.hasInspectorRights()
    ) {
      currentMenuList = currentMenuList.concat([
        {
          name: "Inspector",
          icon: "TestPlan",
          key: "inspector",
          links: [
            {
              name: "Create test",
              url: "/ui/inspector/create-test/",
              key: "create-test"
            },
            {
              name: "View results",
              url: "/ui/inspector/view-results",
              key: "view-results"
            },
            {
              name: "Bulk upload",
              url: "/ui/inspector/bulk-upload",
              key: "bulk-upload"
            }
          ]
        }
      ]);
    }
    return currentMenuList;
  };

  const addSettings = (currentMenuList: INavLink[]): INavLink[] => {
    if (currentUser.hasAdminRights()) {
      currentMenuList = currentMenuList.concat([
        {
          name: "Settings",
          icon: "Settings",
          key: "settings",
          links: [
            {
              name: "Settings",
              url: "/ui/settings/settings-page",
              key: "settings-page"
            }
          ]
        }
      ]);
    }
    return currentMenuList;
  };

  const addAbout = (currentMenuList: INavLink[]): INavLink[] => {
    currentMenuList = currentMenuList.concat([
      {
        name: "About",
        icon: "News",
        key: "about",
        links: [
          {
            name: "Service Information",
            url: "/ui/service-information/si",
            key: "si"
          },
          {
            name: "Data Protection Notice",
            url: "https://aka.ms/dpn",
            key: "dataprotection",
            target: "_blank",
            external: true
          },
          {
            name: "PSTN Portal Access",
            url: "/ui/docs/pstn-portal-access",
            key: "pstn-portal-access"
          },
          {
            name: "CBA setup",
            url: "/ui/docs/cba-setup",
            key: "cba-setup"
          }
        ]
      }
    ]);
    return currentMenuList;
  };

  return (
    <div className="navBarContainer">
      <div className="navBarComponent">
        <Nav groups={getMenuGroups()} styles={compStyles} />
      </div>
    </div>
  );
};
