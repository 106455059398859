import React, { useState, useEffect, useRef, useMemo } from "react";
import { PageHeader } from "../../components/layout/page-header/PageHeader";
import { PageLayout } from "../../components/layout/page-layout/PageLayout";
import {
  Stack,
  CommandBarButton,
  MessageBar,
  MessageBarType
} from "@fluentui/react";
import { Card, CardSection } from "@fluentui/react-cards";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { InputWithTooltip } from "../../components/DataPanel/InputWithTooltip";
import { SelectWithTooltip } from "../../components/DataPanel/SelectWithTooltip";
import { PpButton } from "../../components/Widgets/button/Button";
import { object, string, array } from "yup";
import countries from "../../data/countries.json";
import { AuditReasonDialog } from "../../components/AuditTrail/AuditReasonDialog";
import { AuditTrail } from "../../components/AuditTrail/AuditTrail";
import { PageSection } from "../../components/layout/page-section/PageSection";

interface EmergencyNumber {
  dialString: string;
  dialMask?: string | null;
}

interface EmergencyNumbersRequest {
  isoCountryCode: string;
  emergencyNumbers: EmergencyNumber[];
}

type EmergencyNumbersPageProps = {
  context: PortalContext;
};

export function EmergencyNumbersPage(props: EmergencyNumbersPageProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [apiEmergencyNumbers, setApiEmergencyNumbers] = useState<
    EmergencyNumber[]
  >([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState<string>("");
  const [messageBarInfo, setMessageBarInfo] = useState<{
    message: string;
    messageType: MessageBarType;
  } | null>(null);

  const messageBarTimer = useRef<NodeJS.Timeout | null>(null);
  const [showAuditReason, setShowAuditReason] = useState<boolean>(false);
  const auditReasonPromiseRef = useRef<{
    resolve: (reason: string) => void;
    reject: () => void;
  } | null>(null);
  const [showAuditTrail, setShowAuditTrail] = useState<boolean>(false);

  const fetchEmergencyNumbers = async (countryCode: string) => {
    if (countryCode) {
      setIsLoading(true);
      setMessageBarInfo(null);

      try {
        const response = await window.fetch(
          `/ui/api/EmergencyNumbers/Get/${countryCode}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json"
            }
          }
        );

        if (response.ok) {
          const data = await response.json();
          if (data.emergencyNumbers && data.emergencyNumbers.length > 0) {
            setApiEmergencyNumbers(data.emergencyNumbers);
            setMessageBarInfo({
              message: "Emergency numbers fetched successfully!",
              messageType: MessageBarType.success
            });
          } else {
            setApiEmergencyNumbers([]);
            setMessageBarInfo({
              message:
                "No emergency numbers found for the specified country code.",
              messageType: MessageBarType.error
            });
          }
        } else if (response.status === 404) {
          setApiEmergencyNumbers([]);
          setMessageBarInfo({
            message:
              "No emergency numbers found for the specified country code.",
            messageType: MessageBarType.error
          });
        } else {
          const errorData = await response.json();
          throw new Error(
            errorData.message || "Error fetching emergency numbers."
          );
        }
      } catch (error) {
        setApiEmergencyNumbers([]);
        setMessageBarInfo({
          message:
            error instanceof Error
              ? error.message
              : "Error fetching emergency numbers.",
          messageType: MessageBarType.error
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const validationSchema = object().shape({
    isoCountryCode: string()
      .required("Country code is required")
      .oneOf(
        Object.keys(
          countries.reduce((options: { [key: string]: string }, country) => {
            options[country.code] = country.code;
            return options;
          }, {})
        ),
        "Invalid country code"
      ),
    emergencyNumbers: array().of(
      object().shape({
        dialString: string()
          .matches(/^[0-9]+$/, "DialString must be numeric")
          .required("DialString is required"),
        dialMask: string()
          .nullable()
          .matches(/^[0-9]*$/, "DialMask must be numeric (if provided)")
      })
    )
  });

  const getAuditReason = () => {
    return new Promise<string>((resolve, reject) => {
      auditReasonPromiseRef.current = { resolve, reject };
      setShowAuditReason(true);
    });
  };

  const handleSubmit = async (values: EmergencyNumbersRequest) => {
    try {
      const reason = await getAuditReason();
      if (!reason) return;

      // Remove DialMask if it is empty
      const sanitizedEmergencyNumbers = values.emergencyNumbers.map(
        ({ dialString, dialMask }) => {
          const sanitizedNumber: {
            DialString: string;
            DialMask?: string;
          } = { DialString: dialString };
          if (dialMask) {
            sanitizedNumber.DialMask = dialMask;
          }
          return sanitizedNumber;
        }
      );

      const requestBody = {
        IsoCountryCode: values.isoCountryCode,
        EmergencyNumbers: sanitizedEmergencyNumbers
      };

      setIsLoading(true);
      setMessageBarInfo(null);

      const response = await window.fetch(`/ui/api/EmergencyNumbers/Save`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          reason
        },
        body: JSON.stringify(requestBody)
      });
      const data = await response.json();
      if (response.ok) {
        setMessageBarInfo({
          message: "Emergency numbers updated successfully!",
          messageType: MessageBarType.success
        });
      } else {
        throw new Error(data.message || "Failed to update emergency numbers.");
      }
    } catch (error) {
      setMessageBarInfo({
        message:
          error instanceof Error
            ? error.message
            : "Failed to update emergency numbers.",
        messageType: MessageBarType.error
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (messageBarInfo) {
      if (messageBarTimer.current) {
        clearTimeout(messageBarTimer.current);
      }
      messageBarTimer.current = setTimeout(() => {
        setMessageBarInfo(null);
      }, 5000);
    }
    return () => {
      if (messageBarTimer.current) {
        clearTimeout(messageBarTimer.current);
      }
    };
  }, [messageBarInfo]);

  const countryOptions = useMemo(() => {
    const options = Object.fromEntries(
      countries.map(country => [country.code, country.code])
    );
    return { "": "", ...options };
  }, []);
  return (
    <>
      <PageLayout>
        <PageHeader title="Global Emergency Numbers" />
        <PageSection>
          <Stack
            horizontal
            horizontalAlign="end"
            styles={{ root: { marginBottom: 16 } }}
          >
            <CommandBarButton
              ariaLabel="Audit Trail"
              title="Audit Trail"
              className="icq-audit-trail"
              iconProps={{ iconName: "GroupedDescending" }}
              onClick={() => setShowAuditTrail(true)}
            />
          </Stack>
          {showAuditTrail && (
            <AuditTrail
              trailType="EmergencyNumbers"
              isOpen={showAuditTrail}
              onDismiss={() => setShowAuditTrail(false)}
            />
          )}
        </PageSection>

        {messageBarInfo && (
          <MessageBar
            messageBarType={messageBarInfo.messageType}
            isMultiline={false}
            onDismiss={() => setMessageBarInfo(null)}
            dismissButtonAriaLabel="Close"
            styles={{ root: { marginBottom: 20 } }}
          >
            {messageBarInfo.message}
          </MessageBar>
        )}

        <Formik
          initialValues={{
            isoCountryCode: selectedCountryCode,
            emergencyNumbers:
              apiEmergencyNumbers.length > 0
                ? apiEmergencyNumbers
                : [{ dialString: "", dialMask: "" }]
          }}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, setFieldValue, isValid }) => (
            <Form>
              <Stack tokens={{ childrenGap: 20 }}>
                <Field
                  name="isoCountryCode"
                  as={SelectWithTooltip}
                  label="Country Code"
                  options={countryOptions}
                  enableSearch={true}
                  placeholder="Search code"
                  error={touched.isoCountryCode && errors.isoCountryCode}
                  value={values.isoCountryCode}
                  onValueChange={(newValue: string | undefined) => {
                    if (newValue) {
                      setFieldValue("isoCountryCode", newValue);
                      setSelectedCountryCode(newValue);
                      setMessageBarInfo(null);
                      fetchEmergencyNumbers(newValue);
                    }
                  }}
                />
                <ErrorMessage
                  name="isoCountryCode"
                  render={msg => (
                    <div style={{ color: "red", fontSize: "0.9em" }}>{msg}</div>
                  )}
                />

                {values.emergencyNumbers.map((_, index) => (
                  <Card key={index}>
                    <CardSection>
                      <Field
                        name={`emergencyNumbers[${index}].dialString`}
                        as={InputWithTooltip}
                        label="DialString"
                        placeholder="Enter emergency number"
                        error={
                          Array.isArray(errors.emergencyNumbers) &&
                          touched.emergencyNumbers?.[index]?.dialString &&
                          (errors.emergencyNumbers[index] as any)?.dialString
                        }
                      />
                      <Field
                        name={`emergencyNumbers[${index}].dialMask`}
                        as={InputWithTooltip}
                        label="DialMask (optional)"
                        placeholder="Enter dial mask"
                        error={
                          Array.isArray(errors.emergencyNumbers) &&
                          touched.emergencyNumbers?.[index]?.dialMask &&
                          (errors.emergencyNumbers[index] as any)?.dialMask
                        }
                      />
                      <PpButton
                        type="button"
                        text="Remove"
                        onClick={() => {
                          setFieldValue(
                            "emergencyNumbers",
                            values.emergencyNumbers.filter(
                              (_, i) => i !== index
                            )
                          );
                        }}
                      />
                    </CardSection>
                  </Card>
                ))}

                <PpButton
                  type="button"
                  text="Add Emergency Number"
                  onClick={() =>
                    setFieldValue("emergencyNumbers", [
                      ...values.emergencyNumbers,
                      { dialString: "", dialMask: "" }
                    ])
                  }
                />
              </Stack>
              <PpButton
                type="submit"
                disabled={!isValid || isLoading}
                text={isLoading ? "Submitting..." : "Submit"}
              />
            </Form>
          )}
        </Formik>
      </PageLayout>
      <AuditReasonDialog
        isOpen={showAuditReason}
        onSave={reason => {
          setShowAuditReason(false);
          if (auditReasonPromiseRef.current) {
            auditReasonPromiseRef.current.resolve(reason);
            auditReasonPromiseRef.current = null;
          }
        }}
        onCancel={() => {
          setShowAuditReason(false);
          if (auditReasonPromiseRef.current) {
            auditReasonPromiseRef.current.reject();
            auditReasonPromiseRef.current = null;
          }
        }}
      />
    </>
  );
}
