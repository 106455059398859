import { useState } from "react";
import { PageLayout } from "../../components/layout/page-layout/PageLayout";
import { PageHeader } from "../../components/layout/page-header/PageHeader";
import { Breadcrumb } from "../../components/Widgets/breadcrumb/Breadcrumb";
import { PageSection } from "../../components/layout/page-section/PageSection";
import { ConfigPage } from "../../components/ConfigPage/ConfigPage";
import { fetchConfiguration, saveConfiguration } from "../../services/dclid";
import { DclidConfiguration } from "./DclidConfiguration";
import { AddNewDclid } from "./AddNewDclid";

type DclidManagementPageProps = {
  context: PortalContext;
};

export function DclidManagementPage(props: DclidManagementPageProps) {
  const [addNewItemIsShown, setAddNewItemIsShown] = useState(false);
  return (
    <>
      <Breadcrumb />
      <PageLayout>
        <PageHeader title="DCLID Management" />
        <PageSection>
          <ConfigPage
            title="DCLID Management"
            context={props.context}
            auditTrailType={"DclidManagement"}
            fetchConfiguration={fetchConfiguration}
            saveConfiguration={saveConfiguration}
            onAddNewItemClicked={() => setAddNewItemIsShown(true)}
            render={(config, onChange, disabled) => {
              return (
                <div>
                  <DclidConfiguration
                    config={config}
                    onChange={onChange}
                    disabled={disabled}
                  />
                  <AddNewDclid
                    isShown={addNewItemIsShown}
                    allCarriers={config.managedCarriers}
                    onCreate={(
                      destination,
                      replacement,
                      replacementCarriers
                    ) => {
                      const newConfig: DclidManagement = {
                        ...config,
                        configuration: {
                          ...config.configuration,
                          value: [
                            ...(config.configuration.value ?? []),
                            {
                              destination: destination,
                              replacement: replacement,
                              replacementCarriers: replacementCarriers
                            }
                          ]
                        }
                      };
                      onChange(newConfig);
                      setAddNewItemIsShown(false);
                    }}
                    onDismiss={() => setAddNewItemIsShown(false)}
                  />
                </div>
              );
            }}
          />
        </PageSection>
      </PageLayout>
    </>
  );
}
