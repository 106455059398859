import { IPublicClientApplication, InteractionType } from "@azure/msal-browser";
import {
  MsalAuthenticationResult,
  MsalAuthenticationTemplate,
  MsalProvider
} from "@azure/msal-react";
import { Spinner, Stack, PrimaryButton, Text } from "@fluentui/react";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { usePortalContext } from "../../PortalContextProvider";
import { createAuthConfig, createMsalInstance } from "./AuthProviderUtils";

interface Props {
  children?: ReactNode;
}

function ErrorComponent({ error, login }: MsalAuthenticationResult) {
  const loginRedirect = () => {
    login(InteractionType.Redirect);
  };
  return (
    <Stack
      verticalAlign="center"
      horizontalAlign="center"
      styles={{ root: { height: "100vh", textAlign: "center" } }}
      tokens={{ childrenGap: 10 }}
    >
      <Text variant="xxLarge">Authentication error</Text>
      <Text>{error?.message}</Text>
      <PrimaryButton text="Login" onClick={loginRedirect} />
    </Stack>
  );
}

function LoadingComponent() {
  return <Spinner label="Loading..." />;
}

/**
 * Renders configured {@link MsalProvider}. This must be rendered above any other components that use MSAL.
 */
export function AuthProvider({ children }: Props) {
  const context = usePortalContext();
  const [loading, setLoading] = useState(true);
  const [msalInstance, setMsalInstance] = useState<
    IPublicClientApplication | undefined
  >(undefined);

  const authConfig = useMemo(
    () => (context ? createAuthConfig(context) : null),
    [context]
  );

  useEffect(() => {
    if (!authConfig) {
      return;
    }

    createMsalInstance(authConfig)
      .then(setMsalInstance)
      .finally(() => {
        setLoading(false);
      });
  }, [authConfig]);

  return (
    <>
      {loading || !msalInstance ? (
        <LoadingComponent />
      ) : (
        <MsalProvider instance={msalInstance}>{children}</MsalProvider>
      )}
    </>
  );
}

/**
 * Triggers authentication redirect if user is not authenticated.
 */
export function AuthTrigger({ children }: Props) {
  const context = usePortalContext();
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      errorComponent={ErrorComponent}
      loadingComponent={LoadingComponent}
      authenticationRequest={{
        scopes: context.msal.scopes,
        prompt: "select_account"
      }}
    >
      {children}
    </MsalAuthenticationTemplate>
  );
}
