import React from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { GuardedRoute } from "./components/Auth/GuardedRoute";
import { Layout } from "./components/layout/app-layout/AppLayout";
import { ServiceInformation } from "./views/ServiceInformation/ServiceInformation";
import { InspectorProxyPage } from "./views/InspectorProxy/InspectorProxyPage";
import InspectorCreateTestPage from "./views/Inspector/InspectorCreateTestPage";
import { IvtReprovisioningPage } from "./views/IvtReprovisioning/IvtReprovisioningPage";
import GatewaysPage from "./views/Gateways/GatewaysPage";
import CarriersPage from "./views/Carriers/CarriersPage";
import CallingProfilesPage from "./views/CallingProfiles/CallingProfilesPage";
import { SettingsPage } from "./views/Settings/SettingsPage";
import { RoutingDocumentsPage } from "./views/RoutingDocuments/RoutingDocumentsPage";
import { TrnsPage } from "./views/Trns/TrnsPage";
import { ConsumerRoutingsPage } from "./views/DynamicRouting/ConsumerRoutingsPage";
import { ClidReplacementPage } from "./views/ClidReplacement/ClidReplacementPage";
import { DclidManagementPage } from "./views/DclidManagement/DclidManagementPage";
import { SipDiagnosticsPage } from "./views/SipDiagnostics/SipDiagnosticsPage";
import { ClidRestrictionPage } from "./views/ClidRestriction/ClidRestrictionPage";
import { CallFinderPage } from "./views/CallFinder/CallFinderPage";
import { PrefixesPage } from "./views/DynamicRouting/PrefixesPage";
import RouteManagementPage from "./views/DynamicRouting/RouteManagementPage";
import { IcqPage } from "./views/DynamicRouting/IcqPage";
import BatmanPage from "./views/Batman/BatmanPage";
import { CallingProfileDataSyncChecker } from "./views/CallingProfileDataSyncChecker/CallingProfileDataSyncChecker";
import { PstnPortalAccess } from "./views/Documentation/PstnPortalAccess";
import { CbaSetup } from "./views/Documentation/CbaSetup";
import CliConfigurationPage from "./views/DynamicRouting/CliConfiguration/CliConfigurationPage";
import CallingProfileJson from "./views/CallingProfiles/CallingProfileJson";
import CallingProfileEvents from "./views/CallingProfiles/CallingProfileEvents";
import CarrierTestResultsPage from "./views/Carriers/CarrierTestResultsPage";
import { PageNotFound } from "./views/PageNotFound";
import EmergencyCallingRoutingPage from "./views/Carriers/EmergencyCallingRoutingPage";
import InspectorViewResultsPage from "./views/Inspector/InspectorViewResults";
import { InspectorBulkUploadPage } from "./views/Inspector/InspectorBulkUpload";
import TestUsersMigration from "./views/Inspector/TestUsersMigration";
import { User } from "./models/user";
import {
  PortalContextProvider,
  useCurrentUser,
  usePortalContext
} from "./PortalContextProvider";
import { AuthProvider, AuthTrigger } from "./components/Auth/AuthProvider";
import { EmergencyNumbersPage } from "./views/EmergencyNumbers/EmergencyNumbersPage";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

interface IProps {
  basename?: string;
}

export function App(props: IProps): React.ReactElement {
  return (
    <QueryClientProvider client={queryClient}>
      <AppWithContext {...props} />
    </QueryClientProvider>
  );
}

function AppWithContext({ basename }: IProps): React.ReactElement {
  return (
    <PortalContextProvider>
      <AuthProvider>
        <AuthTrigger>
          <AppRouting basename={basename} />
        </AuthTrigger>
      </AuthProvider>
    </PortalContextProvider>
  );
}

function AppRouting({ basename }: IProps): React.ReactElement {
  const context = usePortalContext();
  const currentUser = useCurrentUser();

  if (!currentUser || !context.currentUser) {
    return <>Not logged in</>;
  }

  return (
    <BrowserRouter basename={basename}>
      <Layout context={context}>
        <Switch>
          <Route exact path="/">
            <Redirect to={getHomePage(currentUser)} />
          </Route>
          <Route exact path="/ui">
            <Redirect to={getHomePage(currentUser)} />
          </Route>
          <GuardedRoute
            path="/ui/carrier/carriers"
            roles={["CarrierManagement"]}
            hiddenRoles={["DynamicRouting"]}
            render={() => <CarriersPage context={context} />}
          />
          <GuardedRoute
            path="/ui/carrier/gateways"
            roles={["CarrierManagement"]}
            hiddenRoles={["DynamicRouting"]}
            render={() => <GatewaysPage context={context} />}
          />
          <GuardedRoute
            path="/ui/carrier/ec-routing"
            roles={["CarrierManagement"]}
            render={() => <EmergencyCallingRoutingPage context={context} />}
          />
          <GuardedRoute
            path="/ui/carrier/testresults"
            roles={["CarrierManagement"]}
            render={() => <CarrierTestResultsPage context={context} />}
          />
          <GuardedRoute
            path="/ui/routing/documents"
            roles={["CarrierManagement"]}
            hiddenRoles={["DynamicRouting"]}
            render={() => <RoutingDocumentsPage />}
          />
          <GuardedRoute
            path="/ui/routing/consumer"
            roles={["DynamicRouting"]}
            render={() => <ConsumerRoutingsPage />}
          />
          <GuardedRoute
            path="/ui/routing/trns"
            roles={["CarrierManagement"]}
            hiddenRoles={["DynamicRouting"]}
            render={() => <TrnsPage />}
          />
          <GuardedRoute
            path="/ui/routing/global-emergency-numbers/"
            roles={["CarrierManagement"]}
            hiddenRoles={["CarrierManagement"]}
            render={() => <EmergencyNumbersPage context={context} />}
          />
          <GuardedRoute
            path="/ui/tools/sip-diagnostics"
            roles={["SupportTools"]}
            hiddenRoles={["CarrierManagement"]}
            render={() => <SipDiagnosticsPage />}
          />
          <Route
            path="/ui/tools/callfinder"
            render={() => <CallFinderPage />}
          />

          <GuardedRoute
            path="/ui/routing/clidreplacement"
            roles={["CarrierManagement", "ClidRead"]}
            hiddenRoles={["DynamicRouting"]}
            render={() => <ClidReplacementPage context={context} />}
          />
          <GuardedRoute
            path="/ui/routing/clidrestriction"
            roles={["CarrierManagement", "ClidRead"]}
            hiddenRoles={["DynamicRouting"]}
            render={() => <ClidRestrictionPage context={context} />}
          />
          <GuardedRoute
            path="/ui/routing/dclid"
            roles={["CarrierManagement", "ClidRead"]}
            render={() => <DclidManagementPage context={context} />}
          />
          <GuardedRoute
            path="/ui/operator-connect/calling-profiles"
            roles={["CarrierManagement"]}
            hiddenRoles={["DynamicRouting"]}
            render={() => <CallingProfilesPage context={context} />}
          />
          <GuardedRoute
            path="/ui/operator-connect/calling-profile"
            roles={["Admin"]}
            render={() => <CallingProfileJson />}
          />
          <GuardedRoute
            path="/ui/operator-connect/calling-profile-events"
            roles={["Admin"]}
            render={() => <CallingProfileEvents />}
          />
          <GuardedRoute
            path="/ui/operator-connect/debug/out-of-sync"
            roles={["Admin"]}
            render={() => <CallingProfileDataSyncChecker />}
          />
          <GuardedRoute
            path="/ui/settings/settings-page"
            roles={["Admin"]}
            render={() => <SettingsPage context={context} />}
          />
          <GuardedRoute
            path="/ui/tools/inspector-request"
            roles={["Admin"]}
            render={() => <InspectorProxyPage context={context} />}
          />
          <GuardedRoute
            path="/ui/tools/ivt-reprovisioning"
            roles={["Admin"]}
            render={() => <IvtReprovisioningPage />}
          />
          <GuardedRoute
            path="/ui/inspector/create-test"
            roles={["Inspector"]}
            hiddenRoles={["CarrierManagement"]}
            render={() => <InspectorCreateTestPage />}
          />
          <GuardedRoute
            path="/ui/inspector/view-results"
            roles={["Inspector"]}
            hiddenRoles={["CarrierManagement"]}
            render={() => <InspectorViewResultsPage />}
          />
          <GuardedRoute
            path="/ui/inspector/bulk-upload"
            roles={["Inspector"]}
            hiddenRoles={["CarrierManagement"]}
            render={() => <InspectorBulkUploadPage />}
          />
          <GuardedRoute
            path="/ui/inspector/migrate-test-user-passwords"
            roles={["Admin"]}
            render={() => <TestUsersMigration />}
          />
          <GuardedRoute
            path="/ui/routing/prefixes"
            roles={["DynamicRouting"]}
            render={() => <PrefixesPage context={context} />}
          />
          <GuardedRoute
            path="/ui/routing/batman"
            roles={["DynamicRouting"]}
            render={() => <BatmanPage context={context} />}
          />
          <GuardedRoute
            path="/ui/routing/route-management"
            roles={["DynamicRouting"]}
            render={() => <RouteManagementPage context={context} />}
          />
          <GuardedRoute
            path="/ui/routing/icq"
            roles={["DynamicRouting"]}
            render={() => <IcqPage />}
          />
          <GuardedRoute
            path="/ui/routing/cli"
            roles={["DynamicRouting"]}
            render={() => <CliConfigurationPage context={context} />}
          />

          <Route
            path="/ui/service-information/si"
            render={() => <ServiceInformation context={context} />}
          />

          <Route
            path="/ui/docs/pstn-portal-access"
            component={PstnPortalAccess}
          />

          <Route path="/ui/docs/cba-setup" component={CbaSetup} />

          <Route path="*" component={PageNotFound} />
        </Switch>
      </Layout>
    </BrowserRouter>
  );
}

function getHomePage(user: User): string {
  if (user.hasCarrierManagementRights()) {
    return "/ui/carrier/carriers";
  } else if (user.hasDynamicRoutingRights()) {
    return "/ui/routing/prefixes";
  } else {
    return "/ui/tools/callfinder";
  }
}
