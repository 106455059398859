import {
  AuthenticationResult,
  Configuration,
  EventType,
  IPublicClientApplication,
  PublicClientApplication,
  SilentRequest
} from "@azure/msal-browser";
import { ReloginRequiredError } from "../../services/http-client";

export function createAuthConfig(config: PortalContext) {
  return {
    auth: {
      clientId: config.msal.clientId,
      authority: config.msal.authority,
      redirectUri: "/ui", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
      navigateToLoginRequestUrl: true // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
      cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
      storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
}

export async function createMsalInstance(authConfig: Configuration) {
  const msalInstance =
    await PublicClientApplication.createPublicClientApplication(authConfig);

  try {
    await msalInstance.handleRedirectPromise();
  } catch (error) {
    console.error("Failed to handle redirect promise:", error);
  }

  // Default to using the first account if no account is active on page load
  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  msalInstance.addEventCallback(
    event => {
      const account = (event?.payload as AuthenticationResult)?.account;
      msalInstance.setActiveAccount(account);
    },
    [
      EventType.LOGIN_SUCCESS,
      EventType.ACQUIRE_TOKEN_SUCCESS,
      EventType.SSO_SILENT_SUCCESS
    ]
  );

  return msalInstance;
}

export type AccessTokenProviderParams = Partial<
  Pick<
    SilentRequest,
    "resourceRequestMethod" | "resourceRequestUri" | "authenticationScheme"
  >
>;

const createAccessTokenProvider = () => {
  let lock: Promise<void> | null = null;
  let reloginInProgress = false;

  return async (
    instance: IPublicClientApplication,
    params: SilentRequest
  ): Promise<string> => {
    while (lock) {
      await lock;
    }

    let resolveLock: () => void;
    lock = new Promise<void>(resolve => {
      resolveLock = resolve;
    });

    try {
      if (reloginInProgress) {
        throw new ReloginRequiredError("Relogin in progress");
      }

      try {
        const tokenResponse = await instance.acquireTokenSilent(params);
        return tokenResponse.accessToken;
      } catch (error) {
        console.error("Failed to acquire token silently:", error);
      }

      try {
        const tokenResponse = await instance.acquireTokenPopup(params);
        return tokenResponse.accessToken;
      } catch (popupError) {
        console.error("Failed to acquire token using popup:", popupError);
      }

      reloginInProgress = true;
      await instance.acquireTokenRedirect(params);

      throw new ReloginRequiredError("Failed to acquire token");
    } finally {
      lock = null;
      resolveLock!();
    }
  };
};

export const getAccessToken = createAccessTokenProvider();
