import { AuthenticationScheme } from "@azure/msal-browser";
import { AccessTokenProviderParams } from "../components/Auth/AuthProviderUtils";

const originalFetch = globalThis.fetch;

export class ReloginRequiredError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "ReloginRequiredError";
  }
}

export type AccessTokenProvider = (
  params: AccessTokenProviderParams
) => Promise<string>;

let accessTokenProvider: AccessTokenProvider | null = null;

export function setAccessTokenProvider(provider: AccessTokenProvider): void {
  accessTokenProvider = provider;
}

export async function fetch(
  input: RequestInfo | URL,
  init: RequestInit = {}
): Promise<Response> {
  const isAPICall =
    typeof input === "string" &&
    (input.toLowerCase().startsWith("/ui/api") ||
      input.toLowerCase().startsWith("ui/api"));
  const requiresAuthentication =
    isAPICall && input !== "ui/api/Settings/GetPortalContext";

  if (isAPICall && (init?.method || "GET") !== "GET") {
    const csrfToken = await getCSRFToken();

    addHeaders(init, {
      "X-CSRF-TOKEN": csrfToken
    });
  }

  if (isAPICall && requiresAuthentication) {
    return fetchWithAccessToken(input, init);
  } else {
    return originalFetch(input, init);
  }
}

async function getCSRFToken(retryCount: number = 3): Promise<string> {
  if (retryCount < 1) {
    throw new Error("Max retry attempts reached for fetching CSRF token.");
  }

  try {
    const response = await fetchWithAccessToken("/ui/api/antiforgery/token");

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data.token;
  } catch (error) {
    if (error instanceof ReloginRequiredError) {
      throw error;
    }
    console.error("Failed to fetch CSRF token:", error);
    console.log(`Retrying... (${retryCount - 1} attempts left)`);
    return getCSRFToken(retryCount - 1);
  }
}

async function fetchWithAccessToken(
  input: RequestInfo | URL,
  init: RequestInit = {}
): Promise<Response> {
  if (!accessTokenProvider) {
    throw new Error("Access token provider is not set");
  }

  const inputStr = input.toString();
  const normalizedResourceUrl = inputStr.startsWith("/")
    ? inputStr.substring(1)
    : inputStr;
  const accessToken = await accessTokenProvider({
    authenticationScheme: AuthenticationScheme.POP,
    resourceRequestMethod: init?.method || "GET",
    resourceRequestUri: `https://${window.location.host}/${normalizedResourceUrl}`
  });

  addHeaders(init, {
    Authorization: `PoP ${accessToken}`
  });

  return originalFetch(input, init);
}

function addHeaders(init: RequestInit, headers: Record<string, string>): void {
  if (!init.headers) {
    init.headers = {};
  }

  if (init.headers instanceof Headers) {
    for (const [key, value] of Object.entries(headers)) {
      init.headers.append(key, value);
    }
  } else if (typeof init.headers === "object") {
    for (const [key, value] of Object.entries(headers)) {
      (init.headers as Record<string, string>)[key] = value;
    }
  }
}
