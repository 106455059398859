import { Dropdown, IDropdownOption } from "@fluentui/react";

type CarrierSelectProps = {
  selectedCarrier?: CarrierConfig | undefined;
  onChange: (newSelection: CarrierConfig) => void;
  disabled?: boolean;
  allCarriers: CarrierConfig[];
};

export function CarrierSelect({
  selectedCarrier,
  onChange,
  disabled = false,
  allCarriers
}: CarrierSelectProps) {
  const options: IDropdownOption[] = allCarriers.map(carrier => {
    return {
      key: carrier.partnerId,
      text: carrier.name,
      // XXX: DCLID
      disabled: false,
      data: carrier
    };
  });

  return (
    <Dropdown
      ariaLabel="carrier selection"
      data-testid="carrier-select"
      placeholder="Select Carrier"
      selectedKey={selectedCarrier?.partnerId ?? null}
      options={options}
      onChange={(_, item) => {
        const carrier = item?.data as CarrierConfig;
        if (carrier) {
          onChange(carrier);
        }
      }}
      disabled={disabled}
    />
  );
}
