import {
  FontIcon,
  IconButton,
  IIconProps,
  Stack,
  IStackItemStyles,
  IStackTokens,
  TextField,
  getTheme
} from "@fluentui/react";
import { CarrierSelect } from "./CarrierSelect";

type DataListRowProps = {
  item: CarrierConfig;
  disabledCarriers: CarrierConfig[] | undefined;
  allCarriers: CarrierConfig[];
  onChange: (item: CarrierConfig | undefined) => void;
  disabled?: boolean;
};

const theme = getTheme();
const clearIconProps: IIconProps = {
  iconName: "Clear",
  styles: {
    root: { fontSize: 12, color: theme.palette.redDark }
  }
};
const rowStackTokens: IStackTokens = { childrenGap: 12 };
const rowStackStyles: IStackItemStyles = { root: { width: "1500" } };

export function DataListRow({
  item,
  disabledCarriers,
  allCarriers,
  onChange,
  disabled = false
}: DataListRowProps) {
  return (
    <Stack
      horizontal
      horizontalAlign="space-between"
      verticalAlign="center"
      styles={rowStackStyles}
      tokens={rowStackTokens}
    >
      <Stack.Item>
        <FontIcon iconName="GlobalNavButton" />
      </Stack.Item>
      <Stack.Item grow styles={{ root: { maxWidth: 220 } }}>
        <CarrierSelect
          selectedCarrier={item}
          onChange={onChange}
          disabled={disabled}
          allCarriers={allCarriers}
        />
      </Stack.Item>
      <Stack.Item>
        <TextField
          data-testid="percentage"
          label=""
          name="percentage"
          type="number"
          min="0"
          max="100"
          step={1}
          value={String(item.percentage)}
          onChange={(_, text) =>
            onChange({ ...item, percentage: Number(text) })
          }
        />
      </Stack.Item>
      <Stack.Item>
        <IconButton
          aria-label="delete carrier"
          data-testid="delete-carrier"
          iconProps={clearIconProps}
          onClick={() => onChange(undefined)}
          disabled={disabled}
        />
      </Stack.Item>
    </Stack>
  );
}
