import { DetailsList, IColumn, SelectionMode } from "@fluentui/react";
import { DataListRow } from "./DataListRow";
import DetailsListDragDropHelper from "../../components/Helpers/DetailsListDragDropHelper";

export type DclidCarrierListProps = {
  disabledCarriers: CarrierConfig[] | undefined;
  selectedCarriers: Array<CarrierConfig>;
  allCarriers: CarrierConfig[];
  onChange: (newData: Array<CarrierConfig>) => void;
  disabled?: boolean;
};

export function DclidCarrierList(props: DclidCarrierListProps) {
  const columns: IColumn[] = [
    {
      name: "",
      key: "row",
      minWidth: 300,
      onRender: (item?: CarrierConfig, index?: number) => {
        return (
          <DataListRow
            item={item!}
            disabledCarriers={props.disabledCarriers}
            allCarriers={props.allCarriers}
            onChange={changedCarrier => {
              handleChange(changedCarrier, index!);
            }}
            disabled={props.disabled}
          />
        );
      }
    }
  ];

  const handleChange = (
    changedCarrier: CarrierConfig | undefined,
    index: number
  ) => {
    let newSelection: Array<CarrierConfig>;
    if (changedCarrier) {
      newSelection = [...props.selectedCarriers];
      newSelection[index!] = changedCarrier;
      props.onChange(newSelection);
    } else {
      newSelection = props.selectedCarriers;
      newSelection = props.selectedCarriers.filter((_, idx) => idx !== index);
    }
    props.onChange(newSelection);
  };

  const dragDropHelper = new DetailsListDragDropHelper(
    props.selectedCarriers,
    props.onChange
  );

  return (
    <DetailsList
      isHeaderVisible={false}
      selectionMode={SelectionMode.none}
      columns={columns}
      items={props.selectedCarriers}
      dragDropEvents={
        !props.disabled ? dragDropHelper.DragDropEvents : undefined
      }
    />
  );
}
