import {
  ActionButton,
  IButtonProps,
  Separator,
  Text,
  getTheme
} from "@fluentui/react";
import { Card, CardItem, ICardTokens } from "@fluentui/react-cards";
import { ClidReplacementCountriesList } from "./ClidReplacementCountriesList";
import { CountrySelect } from "../../components/Helpers/CountrySelect";
import { Country } from "../../models/country";

type ClidReplacementProps = {
  disabledDestinationCountries: Country[] | undefined;
  destinationCountry?: Country | undefined;
  replacementCountries?: Country[] | undefined;
  onChange: (
    destionationCountry: Country | undefined,
    replacementCountries: Country[] | undefined
  ) => void;
  showDelete?: boolean;
  disabled?: boolean;
};

const theme = getTheme();
const destionationCountryStyles = {
  root: { paddingLeft: 12, paddingRight: 12, paddingTop: 8 }
};
const newReplacementCountryStyles = {
  root: { paddingLeft: 12, paddingRight: 12, paddingBottom: 8 }
};
const deleteButtonProps: IButtonProps = {
  styles: {
    label: { color: theme.palette.redDark },
    labelHovered: { color: theme.semanticColors.buttonTextHovered }
  },
  iconProps: {
    iconName: "Delete",
    styles: { root: { color: theme.palette.redDark } }
  }
};

export function ClidReplacement(props: ClidReplacementProps) {
  const stackTokens: ICardTokens = {
    childrenGap: 12,
    padding: 12,
    maxWidth: 1000
  };
  // Already selected replacements cannot be added
  const disabledNewCountries = [...(props.replacementCountries ?? [])];
  if (props.destinationCountry) {
    disabledNewCountries.push(props.destinationCountry);
  }
  // Don't allow selecting already selected destination countries and
  // replacement countries as a destination
  const disabledDestinationCountries = [
    ...(props.disabledDestinationCountries ?? []),
    ...(props.replacementCountries ?? [])
  ];
  const shouldRenderReplacements =
    (props.replacementCountries?.length ?? 0) > 0;
  return (
    <Card
      tokens={stackTokens}
      data-testid="ClidConfiguration"
      className="CustomCard"
    >
      <CardItem grow disableShrink align="center">
        <Text variant="smallPlus">Destination Country</Text>
      </CardItem>
      <CardItem grow disableShrink styles={destionationCountryStyles}>
        <CountrySelect
          disabledCountries={disabledDestinationCountries}
          selectedCountry={props.destinationCountry}
          onChange={destinationCountry => {
            props.onChange(destinationCountry, props.replacementCountries);
          }}
          disabled={props.disabled}
        />
      </CardItem>
      {shouldRenderReplacements && (
        <CardItem grow disableShrink>
          <Separator>
            <Text variant="smallPlus">Replacement Countries</Text>
          </Separator>
        </CardItem>
      )}
      {shouldRenderReplacements && (
        <CardItem grow disableShrink>
          <ClidReplacementCountriesList
            disabledCountries={props.replacementCountries}
            selectedCountries={props.replacementCountries || []}
            onChange={selectedCountries => {
              props.onChange(props.destinationCountry, selectedCountries);
            }}
            disabled={props.disabled}
          />
        </CardItem>
      )}
      <CardItem grow disableShrink>
        <Separator>
          <Text variant="smallPlus">Add Replacement Country</Text>
        </Separator>
      </CardItem>
      <CardItem grow disableShrink styles={newReplacementCountryStyles}>
        <CountrySelect
          disabledCountries={disabledNewCountries}
          onChange={selectedCountry => {
            const newReplacements = [
              ...(props.replacementCountries ?? []),
              selectedCountry
            ];
            props.onChange(props.destinationCountry, newReplacements);
          }}
          disabled={props.disabled}
        />
      </CardItem>
      {(props.showDelete ?? true) && (
        <CardItem grow align="center">
          <ActionButton
            disabled={props.disabled}
            iconProps={deleteButtonProps.iconProps}
            styles={deleteButtonProps.styles}
            onClick={() => props.onChange(undefined, undefined)}
          >
            Delete
          </ActionButton>
        </CardItem>
      )}
    </Card>
  );
}
